const backgroundPaper = (theme) => theme.palette.background.paper;
const backgroundDefault = (theme) => theme.palette.background.default;

const displayFlex = { display: "flex" };
const justifyCenter = { justifyContent: "center" };
const flexOne = 1;

export const ParslyWrapper = {
  display: "flex",
  flex: flexOne,
  background: backgroundDefault,

  "@media (min-width: 769px)": {
    flex: "0 0 50%",
    justifyContent: "end",
  },
};

export const ParslyBoxContent = {
  p: "4rem 2.4rem",

  "@media (min-width: 769px)": {
    maxWidth: "79.2rem",
    p: "10.4rem 4.8rem 10.8rem 12rem",
  },
};

export const ParslyLogoWrapper = {
  fontSize: 0,
  ...displayFlex,
  ...justifyCenter,

  height: "6.4rem",
  width: "20rem",

  "@media (min-width: 769px)": {
    justifyContent: "flex-start",
  },
};

export const ContactUsBox = {
  display: "none",

  "@media (min-width: 769px)": {
    mt: "6.4rem",
    display: "block",
  },
};

export const ContactIconWrapper = {
  borderRadius: ".8rem",
  backgroundColor: (theme) => theme.palette.primary.main,
  mr: "1.6rem",
};

export const EmailIconStyles = {
  color: backgroundPaper,
  fontSize: "2.4rem",
};

export const ContactLink = {
  ...displayFlex,
  textDecoration: "none",
  alignItems: "center",
  color: (theme) => theme.palette.text.primary,
};
