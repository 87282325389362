import { createSvgIcon } from "@mui/material/utils";

export const SolidMail = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.40356 7.06085L12 11.8584L21.5964 7.06085C21.5608 6.44938 21.2929 5.87463 20.8473 5.45432C20.4018 5.03401 19.8125 4.79994 19.2 4.80005H4.79996C4.18747 4.79994 3.5981 5.03401 3.15258 5.45432C2.70705 5.87463 2.43909 6.44938 2.40356 7.06085Z"
      fill="currentColor"
    />
    <path
      d="M21.6 9.7417L12 14.5417L2.40002 9.7417V16.8001C2.40002 17.4366 2.65288 18.0471 3.10297 18.4972C3.55306 18.9472 4.1635 19.2001 4.80002 19.2001H19.2C19.8365 19.2001 20.447 18.9472 20.8971 18.4972C21.3472 18.0471 21.6 17.4366 21.6 16.8001V9.7417Z"
      fill="currentColor"
    />
  </svg>,
  "SolidMail"
);
