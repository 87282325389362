import { Box } from "@mui/material";
import { ReactNode } from "react";
import Footer from "../../../login/components/Footer";

import { pageWrapper } from "./styles";

interface ISignUpLayout {
  children: ReactNode;
}

const SignUpLayout = ({ children }: ISignUpLayout) => {
  return (
    <>
      <Box sx={pageWrapper}>{children}</Box>
      <Footer />
    </>
  );
};

export default SignUpLayout;
