const backgroundPaper = (theme) => theme.palette.background.paper;

const displayFlex = { display: "flex" };
const justifyCenter = { justifyContent: "center" };
const flexOne = 1;

export const pageWrapper = {
  ...displayFlex,
  flexDirection: "column",
  flex: flexOne,
  background: backgroundPaper,

  "@media (min-width: 769px)": {
    flexDirection: "row",
    ...justifyCenter,
  },
};
