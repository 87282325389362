import { useTranslation } from "react-i18next";
import { Box, Link } from "@mui/material";

import { ImageApp } from "../../share/image-app";

import {
  footerContent,
  footerLink,
  footerLinks,
  footerMenu,
  footerMenuItem,
  footerWrapper,
} from "./styles";

const Footer = () => {
  const { t } = useTranslation();

  const FooterMenuItems = {
    left: [
      {
        key: "menu-item-474",
        link: t("footer__faq_link"),
        text: t("footer__faq"),
      },
      {
        key: "menu-item-140",
        link: t("footer__careers_link"),
        text: t("footer__careers"),
      },
      {
        key: "menu-item-730",
        link: t("footer__termsAndConditions_link"),
        text: t("footer__termsAndConditions"),
      },
      {
        key: "menu-item-979",
        link: t("footer__cookiesAndPrivacy_link"),
        text: t("footer__cookiesAndPrivacy"),
      },
    ],
    right: [
      {
        key: "footer-tel",
        link: `tel:${t("footer__phoneNumber")}`,
        text: t("footer__phoneNumber"),
      },
      {
        key: "footer-email",
        link: `mailto:${t("footer__email")}`,
        text: t("footer__email"),
      },
      { key: "footer-orgName", link: null, text: t("footer__orgName") },
      { key: "footer-orgNumber", link: null, text: t("footer__orgNumber") },
    ],
  };

  return (
    <Box sx={footerWrapper}>
      <Box sx={footerContent}>
        <Box sx={footerLinks}>
          <Box>
            <ImageApp
              style={{ maxWidth: "10.6rem" }}
              mainImage="https://cdn.parsly.com/images/parsly-logo-white.svg"
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              fontSize: 0,
              mt: "4.6rem",

              "@media (min-width: 560px)": {
                mt: 0,
              },

              "@media (min-width: 1080px)": {
                mt: "4.8rem",
              },
            }}
          >
            <Box
              sx={{
                m: "-1.8rem",
              }}
            >
              <ImageApp
                style={{ width: "14.5rem", margin: "1.8rem" }}
                mainImage="https://parsly.com/media/App-Store-Badge.svg"
              />
              <ImageApp
                style={{ width: "14.5rem", margin: "1.8rem" }}
                mainImage="https://parsly.com/media/Google-Play-Store-Badge.svg"
              />
            </Box>
          </Box> */}
        </Box>
        {Object.keys(FooterMenuItems).map((key) => (
          <Box key={`footerMenuItems-${key}`} sx={footerMenu}>
            <Box>
              {FooterMenuItems[key].map((menu) => (
                <Box key={menu.key} sx={footerMenuItem}>
                  <Link href={menu.link} sx={footerLink}>
                    {menu.text}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
