export const footerWrapper = {
  position: "sticky",
  top: "100%",
  p: "10rem 0 6rem",
  background: "#000",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",

  "@media (min-width: 560px)": {
    padding: "7.4rem 0 8rem",
  },
} as const;

export const footerContent = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "116.8rem",
  padding: "0 3.6rem",
  flex: 1,

  "@media (min-width: 560px)": {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
} as const;

export const footerLinks = {
  px: "0.8rem",

  "@media (min-width: 560px)": {
    flex: "0 0 33.3333%",
  },

  "@media (min-width: 1080px)": {
    flex: "0 0 50%",
  },
};

export const footerMenu = {
  flex: "0 0 33.3333%",
  pt: "6.6rem",
  px: "0.8rem",

  "@media (min-width: 560px)": {
    maxWidth: "33%",
    pt: "1.8rem",
  },
  "@media (min-width: 1080px)": {
    flex: "0 0 25%",
  },
} as const;

export const footerMenuItem = {
  fontSize: "1.3rem",
  fontWeight: 400,
  lineHeight: "1.6rem",
  fontFamily: "NexaRegular",

  "&:not(:first-of-type)": {
    mt: "1.5rem",
  },
};

export const footerLink = {
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "NexaBold",
  color: (theme) => theme.palette.primary.contrastText,
};
