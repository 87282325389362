import { useTranslation } from "react-i18next";
import NextLink from "next/link";

import { Avatar, Box, Link, Typography } from "@mui/material";

import ParslyIcon from "../../../../public/img/icons/parsly-logo.svg";
import { SolidMail as EmailIcon } from "../../../icons/solid-mail";

import {
  ContactIconWrapper,
  ContactLink,
  ContactUsBox,
  EmailIconStyles,
  ParslyBoxContent,
  ParslyLogoWrapper,
  ParslyWrapper,
} from "./styles";
import { ILeftSideMessage } from "./LeftSideMessage.types";
import i18n from "../../../utils/i18n";

const LeftSideMessage = ({ title, message, secondText }: ILeftSideMessage) => {
  const { t } = useTranslation();

  return (
    <Box sx={ParslyWrapper}>
      <Box sx={ParslyBoxContent}>
        <Box sx={ParslyLogoWrapper} data-testid="parsly-logo-sidebar">
          <ParslyIcon />
        </Box>
        <Box sx={ContactUsBox}>
          <NextLink
            href={`https://parsly.com/${
              i18n.language === "en"
                ? "en/contact-us/#:~:text=write%20a%20message.-,Name,-Email%20or%20phone"
                : "kontakt/#:~:text=skriv%20ett%20meddelande.-,Namn,-E%2Dpost%20eller"
            }`}
            passHref
          >
            <Link sx={ContactLink}>
              <Avatar sx={ContactIconWrapper}>
                <EmailIcon sx={EmailIconStyles} />
              </Avatar>
              <Typography variant="overline">
                {t("login_page__contact-us")}
              </Typography>
            </Link>
          </NextLink>
        </Box>
        <Box sx={{ mt: "4.8rem" }}>
          <Typography variant="h1">{t(title)}</Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            sx={{ mt: ".8rem" }}
          >
            {t(message)}
          </Typography>
          {secondText ? (
            <Typography color="textSecondary" variant="body1">
              {t(secondText)}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSideMessage;
